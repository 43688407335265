import React, { useState } from 'react';

import { useLocation, WindowLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import ErrorPanel from 'src/components/error-panel/error-panel';
import Profile from 'src/components/profile/profile';
import Spinner from 'src/components/spinner/spinner';
import Redirect from 'src/components/util-components/redirect';
import { FindOrganisations_findOrganisations_results } from 'src/graphql-types/saas/FindOrganisations';
import { PageReferrer } from 'src/types';
import ROUTES from 'src/utils/routes';

import { defaultConfig } from './config';
import {
  useHasOrgAdminAccess,
  useOrganisationProfile,
  useOrgProfileHeader,
  useOrgProfileTabs,
  useRelationalViewingContext,
} from './hooks';
import { OrganisationProfileProps, OverridableHeaderProps } from './types';

const OrganisationProfile = ({
  orgId,
  tabsConfig = defaultConfig.tabsConfig,
  headerConfig = defaultConfig.headerConfig,
  allowedTypes = defaultConfig.allowedTypes,
}: OrganisationProfileProps) => {
  const { t } = useTranslation();
  const page = useLocation() as WindowLocation<FindOrganisations_findOrganisations_results>;
  const [prefetchedOrg] = useState(
    page.state?.organisationId && page.state.organisationId === orgId ? page.state : undefined,
  );
  const {
    data: orgData,
    loading,
    error: errorOrg,
  } = useOrganisationProfile(orgId, {
    shouldSkip: !!prefetchedOrg || !orgId,
    redirectOnNoData: true,
  });
  const org = prefetchedOrg ?? orgData?.getOrganisationById ?? undefined;
  const { error: errorViewingContext, viewingContext } = useRelationalViewingContext(org);
  const error = errorViewingContext ?? errorOrg;
  const hasOrgAdminAccess = useHasOrgAdminAccess(org);
  const tabs = useOrgProfileTabs({ tabsConfig, org, viewingContext });
  const headerProps = useOrgProfileHeader({
    org,
    config: headerConfig,
  });

  if (error) return <ErrorPanel subtitle={t('organisationsModule.queryErrors.getOrganisationProfile')} />;

  if (loading || !org || !viewingContext) return <Spinner fullScreen />;

  if (!org.organisationType || !allowedTypes.includes(org.organisationType)) return <Redirect to={ROUTES.NOT_FOUND} />;

  return (
    <Profile<'organisation'>
      currentPageName={org.organisationName as string}
      defaultPageReferrer={PageReferrer.MANAGE_ORGANISATIONS}
      header={{
        type: 'organisation',
        props: {
          ...(headerProps as OverridableHeaderProps),
          withAdminAccess: hasOrgAdminAccess,
          id: orgId as string,
        },
      }}
      tabs={tabs}
    />
  );
};

export default OrganisationProfile;
